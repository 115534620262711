<template>
  <div>
    <b-card title="تنظیمات" class="mt-3">
      <div class="row">
        <div class="col-md-4">
          <h3>عمومی</h3>
          <router-link to="/admin/settings/general">تغییر تنظیمات</router-link>
        </div>
        <div class="col-md-4">
          <h3>رسانه</h3>
          <router-link to="/admin/settings/social">تغییر تنظیمات</router-link>
        </div>
        <div class="col-md-4">
          <h3>روش های ارسال</h3>
          <router-link to="/admin/settings/shipping">تغییر تنظیمات</router-link>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
  import mixins from '../mixins/mixins'
  export default {
    mixins: [mixins],
    data() {
      return {
        title: 'تنظیمات'
      }
    }
  }
</script>